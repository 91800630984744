import React, { useState } from 'react';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { StaticImage } from 'gatsby-plugin-image';

import Seo from '../../../Seo';
import { signIn } from '../../../../utils/auth';
import Login from './Login';
import ChangePasswordDialog from './ChangePasswordDialog';
import colors from '../../../../styles/colors';

const LoginScreen = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [errorMsg, setErrorMsg] = useState<string>();
  const [dialogVisibility, setDialogVisibility] = useState(false);
  const [loggedInUser, setLoggedInUser] = useState(null);

  const closeDialog = () => setDialogVisibility(false);

  const handleLogin = async (username: string, password: string) => {
    try {
      setErrorMsg(undefined);
      const authenticatedUser = await signIn(username, password);
      if (authenticatedUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
        setLoggedInUser(authenticatedUser);
        setDialogVisibility(true);
      }
    } catch (error) {
      let errMessage = '';
      if (
        error.code === 'UserNotFoundException' ||
        error.code === 'NotAuthorizedException'
      ) {
        errMessage = 'User name or password is incorrect';
      } else {
        errMessage = `Unexpected error ${error.code}`;
      }
      setErrorMsg(errMessage);
    }
  };

  return (
    <Box
      width="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Box
        width={{ sm: '100%', md: '75%' }}
        boxShadow={isSmallScreen ? 'none' : '0 2px 20px 0 rgba(21,24,36,0.16)'}
        p={{ md: 4 }}
      >
        <Seo title="Login" />
        <Grid container direction="row" spacing={2}>
          <Grid item xs={12} md={6}>
            <StaticImage src="./login.jpeg" alt="New Agent" height={400} />
          </Grid>
          {isSmallScreen && (
            <Grid item xs={12}>
              <Divider />
            </Grid>
          )}
          <Grid item xs={12} md={6}>
            <Box
              p={2}
              height="100%"
              borderLeft={isSmallScreen ? 'none' : `2px solid ${colors.grey}`}
            >
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <Typography variant="h3" color="error">
                    Welcome Back!
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="h5">Login to continue</Typography>
                </Grid>
                <Grid item>
                  <Login error={errorMsg} handleLogin={handleLogin} />
                  <ChangePasswordDialog
                    user={loggedInUser}
                    open={dialogVisibility}
                    handleClose={closeDialog}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default LoginScreen;
