import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Theme } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { updatePassword } from '../../../../utils/auth';
import { VALIDATION_SCHEMA } from '../../../../utils/constants';

const ValidationSchema = Yup.object().shape({
  newPassword: VALIDATION_SCHEMA.password,
  passwordConfirm: VALIDATION_SCHEMA.passwordConfirm,
});

interface ChangePasswordDialogProps {
  user: any;
  open: boolean;
  handleClose: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  content: {
    padding: theme.spacing(5),
  },
}));

const ChangePasswordDialog = ({
  open,
  user,
  handleClose,
}: ChangePasswordDialogProps) => {
  const [errorMsg, setErrorMsg] = useState<string>();
  const classes = useStyles();
  const {
    values: { newPassword, passwordConfirm },
    errors,
    touched,
    isValid,
    isSubmitting,
    dirty,
    handleBlur,
    handleSubmit,
    handleChange,
  } = useFormik({
    initialValues: {
      newPassword: '',
      passwordConfirm: '',
    },
    validationSchema: ValidationSchema,
    onSubmit: async (formValues) => {
      try {
        await updatePassword(user, formValues.newPassword);
        handleClose();
      } catch (error) {
        setErrorMsg('Unexpected error');
      }
    },
  });

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="sm"
      aria-labelledby="chnage-password-dialog"
    >
      <DialogTitle
        id="change-password"
        disableTypography
        className={classes.title}
      >
        <Typography variant="h5">
          Change your password before continuing
        </Typography>
        <IconButton
          aria-label="close"
          disabled={isSubmitting}
          onClick={handleClose}
          className={classes.closeButton}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.content} dividers>
        <form name="login" onSubmit={handleSubmit}>
          <Grid container direction="column" spacing={3}>
            <Grid item>
              <TextField
                fullWidth
                id="newPassword"
                name="newPassword"
                label="New Password"
                type="password"
                value={newPassword}
                onBlur={handleBlur}
                onChange={handleChange}
                disabled={isSubmitting}
                error={touched.newPassword && Boolean(errors.newPassword)}
                helperText={touched.newPassword && errors.newPassword}
              />
            </Grid>
            <Grid item>
              <TextField
                fullWidth
                id="passwordConfirm"
                name="passwordConfirm"
                label="Confirm Password"
                type="password"
                disabled={isSubmitting}
                value={passwordConfirm}
                onBlur={handleBlur}
                onChange={handleChange}
                error={
                  touched.passwordConfirm && Boolean(errors.passwordConfirm)
                }
                helperText={touched.passwordConfirm && errors.passwordConfirm}
              />
            </Grid>
            <Grid item>
              <Box py={1}>
                <Button
                  color="primary"
                  variant="contained"
                  fullWidth
                  type="submit"
                  disabled={!dirty || !isValid || isSubmitting}
                >
                  Update
                </Button>
              </Box>
            </Grid>
            <Grid container item justifyContent="center">
              <Typography variant="body1" color="error">
                {errorMsg}
              </Typography>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default ChangePasswordDialog;
