import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { Link } from 'gatsby-theme-material-ui';
import { ROUTES, VALIDATION_SCHEMA } from '../../../../utils/constants';
import colors from '../../../../styles/colors';

const ValidationSchema = Yup.object().shape({
  username: VALIDATION_SCHEMA.usernameRequired,
  password: VALIDATION_SCHEMA.passwordRequired,
});

interface LoginScreenProps {
  error: string | undefined;
  handleLogin: (username: string, password: string) => void;
}

const LoginScreen = ({ error, handleLogin }: LoginScreenProps) => {
  const {
    values: { username, password },
    errors,
    touched,
    isValid,
    isSubmitting,
    dirty,
    handleBlur,
    handleSubmit,
    handleChange,
  } = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    validationSchema: ValidationSchema,
    onSubmit: async (formValues) => {
      await handleLogin(formValues.username, formValues.password);
    },
  });

  return (
    <form name="login" onSubmit={handleSubmit}>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <TextField
            fullWidth
            id="username"
            name="username"
            label="User Name"
            disabled={isSubmitting}
            value={username}
            onBlur={handleBlur}
            onChange={handleChange}
            error={touched.username && Boolean(errors.username)}
            helperText={touched.username && errors.username}
          />
        </Grid>
        <Grid item>
          <TextField
            fullWidth
            id="password"
            name="password"
            label="Password"
            type="password"
            disabled={isSubmitting}
            value={password}
            onBlur={handleBlur}
            onChange={handleChange}
            error={touched.password && Boolean(errors.password)}
            helperText={touched.password && errors.password}
          />
        </Grid>
        <Grid item container justifyContent="flex-end">
          <Typography variant="body2">
            Forgot your password?{' '}
            <Link to={ROUTES.FORGOT_PASSWORD} style={{ color: colors.danger }}>
              Click here
            </Link>{' '}
            to reset it.
          </Typography>
        </Grid>
        <Grid item>
          <Button
            color="primary"
            variant="contained"
            fullWidth
            type="submit"
            disabled={!dirty || !isValid || isSubmitting}
            endIcon={<ArrowRightAltIcon />}
          >
            Log in
          </Button>
        </Grid>
        <Grid container item justifyContent="center">
          <Typography variant="body1" color="error">
            {error}
          </Typography>
        </Grid>
      </Grid>
    </form>
  );
};

export default LoginScreen;
