import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { VALIDATION_SCHEMA } from '../../../../utils/constants';

const ValidationSchema = Yup.object().shape({
  verificationCode: Yup.string().required('Please enter the verification code'),
  newPassword: VALIDATION_SCHEMA.passwordConfirm,
  passwordConfirm: VALIDATION_SCHEMA.passwordConfirm,
});

interface PasswordResetProps {
  error: string | undefined;
  goBack: () => void;
  resetPassword: (verificationCode: string, newPassword: string) => void;
}

const PasswordReset = ({
  error,
  resetPassword,
  goBack,
}: PasswordResetProps) => {
  const {
    values: { verificationCode, newPassword, passwordConfirm },
    errors,
    touched,
    isValid,
    isSubmitting,
    dirty,
    handleBlur,
    handleSubmit,
    handleChange,
  } = useFormik({
    initialValues: {
      verificationCode: '',
      newPassword: '',
      passwordConfirm: '',
    },
    validationSchema: ValidationSchema,
    onSubmit: async (formValues) => {
      await resetPassword(formValues.verificationCode, formValues.newPassword);
    },
  });

  return (
    <form
      name="reset-password"
      onSubmit={handleSubmit}
      style={{ width: '100%' }}
    >
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Typography variant="h4" color="error">
            Reset your password
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1" color="primary">
            A verification code has been sent to your email!
          </Typography>
        </Grid>
        <Grid item>
          <TextField
            fullWidth
            id="verification-code"
            name="verificationCode"
            label="Verification Code"
            value={verificationCode}
            type="password"
            disabled={isSubmitting}
            onBlur={handleBlur}
            onChange={handleChange}
            error={touched.verificationCode && Boolean(errors.verificationCode)}
            helperText={touched.verificationCode && errors.verificationCode}
          />
        </Grid>
        <Grid item>
          <TextField
            fullWidth
            id="newPassword"
            name="newPassword"
            label="New Password"
            type="password"
            value={newPassword}
            onBlur={handleBlur}
            onChange={handleChange}
            disabled={isSubmitting}
            error={touched.newPassword && Boolean(errors.newPassword)}
            helperText={touched.newPassword && errors.newPassword}
          />
        </Grid>
        <Grid item>
          <TextField
            fullWidth
            id="passwordConfirm"
            name="passwordConfirm"
            label="Confirm Password"
            type="password"
            disabled={isSubmitting}
            value={passwordConfirm}
            onBlur={handleBlur}
            onChange={handleChange}
            error={touched.passwordConfirm && Boolean(errors.passwordConfirm)}
            helperText={touched.passwordConfirm && errors.passwordConfirm}
          />
        </Grid>
        <Grid container item justifyContent="center">
          {error && (
            <Typography variant="body1" color="error">
              {error}
            </Typography>
          )}
        </Grid>
        <Grid item container direction="row" justifyContent="space-around">
          <Grid item xs={4} md={3}>
            <Button fullWidth variant="contained" onClick={goBack}>
              Go back
            </Button>
          </Grid>
          <Grid item xs={4} md={3}>
            <Button
              color="primary"
              variant="contained"
              fullWidth
              type="submit"
              disabled={!isValid || !dirty || isSubmitting}
            >
              Reset
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default PasswordReset;
