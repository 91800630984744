import React from 'react';
import { Router } from '@reach/router';

import LoginScreen from '../../components/screens/auth/LoginScreen';
import ForgotPasswordScreen from '../../components/screens/auth/ForgotPasswordScreen';
import PublicRoute from '../../components/PublicRoute';

const Auth = () => {
  return (
    <Router basepath="/auth">
      <PublicRoute path="/" component={LoginScreen} />
      <PublicRoute path="/forgot-password" component={ForgotPasswordScreen} />
    </Router>
  );
};

export default Auth;
