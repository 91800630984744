import React from 'react';
import { RouteComponentProps } from '@reach/router';

import { navigate } from 'gatsby';
import { useAuthentication } from '../hooks/useAuthentication';
import { ROUTES } from '../utils/constants';

interface PublicRouteProps extends RouteComponentProps {
  component: any;
}

const PublicRoute = ({
  component: Component,
  location,
  ...rest
}: PublicRouteProps) => {
  const { user } = useAuthentication();
  if (user) {
    navigate(ROUTES.HOME);
    return null;
  }

  return <Component {...rest} />;
};

export default PublicRoute;
