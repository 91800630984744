import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import { navigate } from 'gatsby';

import { forgotPassword, resetPassword } from '../../../../utils/auth';
import Seo from '../../../Seo';
import ForgotPassword from './ForgotPassword';
import PasswordReset from './PasswordReset';
import { ROUTES } from '../../../../utils/constants';
import AcknowledgeDialog from '../../../AcknowledgeDialog';

const ForgotPasswordScreen = () => {
  const [errorMsg, setErrorMsg] = useState<string>();
  const [username, setSubmittedUserName] = useState<string>();
  const [successDialogOpen, setSuccessDialogVisibility] = useState(false);

  const handleForgotPassword = async (_username: string) => {
    try {
      await forgotPassword(_username);
      setSubmittedUserName(_username);
    } catch (error) {
      setErrorMsg('Unexpected error');
    }
  };

  const handleResetPassword = async (
    verificationCode: string,
    newPassword: string
  ) => {
    try {
      await resetPassword(username, verificationCode, newPassword);
      setSuccessDialogVisibility(true);
    } catch (error) {
      setErrorMsg('Unexpected error');
    }
  };

  const closeSuccessDialog = () => {
    setSuccessDialogVisibility(false);
    navigate(ROUTES.LOGIN, { replace: true });
  };

  const startOver = () => setSubmittedUserName(undefined);

  return (
    <Box
      width="100%"
      height="500px"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Seo title="Reset Password" />
      {username ? (
        <PasswordReset
          goBack={startOver}
          error={errorMsg}
          resetPassword={handleResetPassword}
        />
      ) : (
        <ForgotPassword
          error={errorMsg}
          forgotPassword={handleForgotPassword}
        />
      )}
      <AcknowledgeDialog
        open={successDialogOpen}
        title="Password Reset"
        content="Your password has been reset successfully. Press OK to go back the Login page"
        onClose={closeSuccessDialog}
      />
    </Box>
  );
};

export default ForgotPasswordScreen;
