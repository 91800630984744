import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { navigate } from '@reach/router';
import { ROUTES, VALIDATION_SCHEMA } from '../../../../utils/constants';

const ValidationSchema = Yup.object().shape({
  username: VALIDATION_SCHEMA.usernameRequired,
});

interface ResetRequestProps {
  error: string | undefined;
  forgotPassword: (username: string) => void;
}

const ForgotPassword = ({ error, forgotPassword }: ResetRequestProps) => {
  const {
    values: { username },
    errors,
    touched,
    isValid,
    isSubmitting,
    dirty,
    handleBlur,
    handleSubmit,
    handleChange,
  } = useFormik({
    initialValues: {
      username: '',
    },
    validationSchema: ValidationSchema,
    onSubmit: async (formValues) => {
      await forgotPassword(formValues.username);
    },
  });

  return (
    <form
      name="forgot-password"
      onSubmit={handleSubmit}
      style={{ width: '100%' }}
    >
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Typography variant="h4" color="error">
            Reset your password
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1" color="primary">
            To reset your password, enter your user name to receive a password
            reset verification code
          </Typography>
        </Grid>
        <Grid item>
          <TextField
            fullWidth
            id="username"
            name="username"
            label="Username"
            value={username}
            disabled={isSubmitting}
            onBlur={handleBlur}
            onChange={handleChange}
            error={touched.username && Boolean(errors.username)}
            helperText={touched.username && errors.username}
          />
        </Grid>
        <Grid container item justifyContent="center">
          {error && (
            <Typography variant="body1" color="error">
              {error}
            </Typography>
          )}
        </Grid>
        <Grid item container direction="row" justifyContent="space-around">
          <Grid item xs={3}>
            <Button
              color="secondary"
              variant="contained"
              fullWidth
              onClick={() => navigate(ROUTES.LOGIN, { replace: true })}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button
              color="primary"
              variant="contained"
              fullWidth
              type="submit"
              disabled={!isValid || !dirty || isSubmitting}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default ForgotPassword;
